import { API } from '@/api';
import { useUser } from '@/api/hooks/useUser';
import { useUserCardFields } from '@/api/hooks/useUserCardFields';
import { IUserData } from '@/api/services/user.service';
import { RuntimeFieldData } from '@/api/user';
import { translate } from '@/i18n';
import bigEmplolyee from '@/images/big-employee.png';
import { useGlobalStore } from '@/stores/globalStore';
import { RolesEnum, useUserStore } from '@/stores/userStore';
import Avatar from '@/ui/components/Avatar';
import { formatPhoneNumber } from '@/utils/helpers/phone.helpers';
import { easePoly } from 'd3-ease';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import Grid from './Grid';
import CloseIcon from './icons/CloseIcon';

interface CardProperty {
    uid: string
    name: string
    attribute: string
    alias: string
    value: any
    type: string
}

function multiIndex(obj,is) {
    return is.length ? multiIndex(obj[is[0]],is.slice(1)) : obj
}

function pathIndex(obj,is) {
    return multiIndex(obj,is.split('.'))
}

export const makeUserCardData = (user?: IUserData, fields?: RuntimeFieldData[]): CardProperty[] => {
    if (!user || !fields) return []


    const paths = fields.map(field => ({
        uid: field.uid,
        label: field.label,
        attribute: field.attribute,
        alias: field.alias,
        value: field.read_path,
        type: field.type
    }))

    return paths.map(path => {
        const property = {
            uid: path.uid,
            name: path.label,
            attribute: path.attribute,
            alias: path.alias,
            value: pathIndex(user, path.value),
            type: path.type
        }

        return property
    })

}

export const BarValue = ({ property }: { property: CardProperty }) => {
    const value = property.type === 'phone' ? formatPhoneNumber(property.value) : property.value;
    if (property.alias === "email") return <Grid.Item $bold><a href={`mailto:${value}`}>{value}</a></Grid.Item>
    return <Grid.Item $bold>{value}</Grid.Item>
}

const EmployeeBar = () => {
    const employee = useGlobalStore(state => state.employee)
    const setEmployee = useGlobalStore(state => state.setEmployee)
    const role = useUserStore(state => state.role)

    const { data } = useUser(employee)
    const { data: extra } = useUserCardFields()

    const users = makeUserCardData(data?.user, extra?.fields)

    const transitions = useTransition(employee, {
        from: { opacity: 0, translateY: 100, translateX: "-50%" },
        enter: { opacity: 1, translateY: 0, translateX: "-50%" },
        leave: { opacity: 0, translateY: 100, translateX: "-50%" },
        config: {
            duration: 300,
            easing: easePoly.exponent(2),
        },
    })

    const userAvatar = API.user.getAvatar({ userId: Number(employee), cache: data?.user.updated_timestamp })

    return transitions(
        (style, item) => item && (
            <Wrapper style={style}>
                <Container $expanded={true}>
                    <Close onClick={() => setEmployee(null)}>
                        <CloseIcon color="#000" />
                    </Close>
                    {data && data.user && (
                        <>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Avatar src={userAvatar || bigEmplolyee} size="large" />
                            </div>
                            <Title>{data.user.display}</Title>

                            <Grid>
                                {users.filter(v => v.value && !v.uid.includes('booking_category')).map(property => (
                                    <Grid.Row $cols="1fr 1fr" key={property.uid}>
                                        <Grid.Item>{property.name}</Grid.Item>
                                        <BarValue property={property} />
                                    </Grid.Row>
                                ))}
                            </Grid>
                        </>
                    )}


                </Container>
            </Wrapper>
        )
    )
}

export default EmployeeBar

const Close = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    &::before {
        content: ' ';
        position: absolute;
        border-radius: 50%;
        top: -5px;
        left: -5px;
        width: 40px;
        height: 40px;
        background: #FFF975;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.5s;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }
`

const Container = styled.div<{ $expanded: boolean }>`
    font-size: 12px;
    margin: 1rem 1.6rem;
    max-height: 0;
    height: auto;   
    transition: all 0.5s;
    overflow: hidden;

    ${Grid.Row} {
        padding: 1rem 0;
    }

    ${({ $expanded }) => $expanded && css`
        max-height: 100%;
    `}
`

const Title = styled.div`
    font-size: 1.6rem;
    line-height: 3rem;
    font-weight: 500;
    margin: 1.2rem 0;
    text-align: center;
    color: #000000;
`

const Wrapper = styled(animated.div)`
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 100%;
    overflow-y: auto;
    max-width: 400px;
    min-height: 90px;
    width: 100%;
    z-index: 9101;
    
    background: #FFFFFF;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
`

const SectionsWrapper = styled.div`
    display: flex;
    padding: 1rem 1rem 0 1rem;
`

const Section = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-basis: 33.3%;
    cursor: pointer;
    position: relative;
    z-index: 10;

    &::before {
        content: ' ';
        position: absolute;
        border-radius: 50%;
        top: 0;
        width: 40px;
        height: 40px;
        background: #FFF975;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.5s;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }
`

const SectionTitle = styled.div<{ $expanded: boolean }>`
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-align: center;
    color: #000000;
    padding-top: 0.8rem;
    max-height: 0;
    height: auto;   
    transition: all 0.5s;
    overflow: hidden;

    ${({ $expanded }) => $expanded && css`
        max-height: 30px;
    `}
`