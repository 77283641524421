import React, { useEffect, useMemo, useRef, useState } from 'react'
import { hexToRgba } from '@/lib/hexToRgb';
import { Line } from 'react-konva';
import { PolygonData } from '@/api/layer';
import { useGlobalStore } from '@/stores/globalStore';
import { Spring, animated } from '@react-spring/konva';
import { easePoly } from 'd3-ease';
import { useMapStore } from '@/stores/mapStore';
import { Group } from 'react-konva';
import { useProjectStore } from '@/stores/projectStore';

interface PolygonElementProps {
    id: number
    width: number
    height: number
    polygon: PolygonData
}

export const getMapCoord = (value: number, percent: number) => value * (percent / 100)

const Polygon: React.FC<PolygonElementProps> = ({ id, width, height, polygon }) => {
    const polygonRef = useRef<any>(null)

    const [fill, setFill] = useState(hexToRgba(polygon.fill, polygon.alpha / 100))
    const [stroke, setStroke] = useState(hexToRgba(polygon.stroke, polygon.alpha / 100))
    const setActiveLayer = useGlobalStore(state => state.setActiveLayer)
    const setLayerModal = useGlobalStore(state => state.setLayerModal)
    const nodes = useProjectStore(state => state.nodes)
    const polygonId = useMapStore(state => state.polygon)
    const setPolygon = useMapStore(state => state.setPolygon)
    const activeLayer = useGlobalStore(state => state.activeLayer)
    const hoverColor = hexToRgba(polygon.fill, polygon.hover / 100)
    const alphaColor = hexToRgba(polygon.fill, polygon.alpha / 100)
    const setTooltip = useMapStore(state => state.setTooltip)
    const node = nodes.find(item => item.id == id)

    const data = polygon.polygon.reduce((acc, val) => [...acc, getMapCoord(width, val[0]), getMapCoord(height, val[1])], [])

    const onMouseEnterHandler = (e) => {
        const container = e.target.getStage()?.container()

        if (container) {
            container.style.cursor = "pointer"
        }

        setFill(hexToRgba(polygon.fill, polygon.hover / 100))
        setStroke(hexToRgba(polygon.stroke, polygon.hover / 100))
    }

    const onMouseLeaveHandler = (e) => {
        const container = e.target.getStage()?.container()

        if (container) {
            container.style.cursor = "default"
        }

        setFill(hexToRgba(polygon.fill, polygon.alpha / 100))
        setStroke(hexToRgba(polygon.stroke, polygon.alpha / 100))
    }

    const handleClick = (e) => {
        e.evt.preventDefault()

        const node = nodes.find(item => item.id == id)
        if (!node || !node.ownView) {
            setLayerModal(id)
        }
        
        setActiveLayer(id)
        setTooltip(null)
        
    }

    const currentPolygon = useMemo(() => polygonId == id, [polygonId, id])

    const onGroupMouseEnterHandler = React.useCallback((e) => {
        setTooltip(node?.name || null)
    }, [setTooltip, node])

    const onGroupMouseLeaveHandler = React.useCallback((e) => {
        setTooltip(null)
    }, [setTooltip])
    
    useEffect(() => {
        const instance = polygonRef.current
        instance.on("mouseenter", onGroupMouseEnterHandler)
        instance.on("mouseleave", onGroupMouseLeaveHandler)

        return () => {
            instance.off("mouseenter")
            instance.off("mouseleave")
        }
     })

    return (
        <Group
            ref={polygonRef}
        >
            <Spring
                from={{ opacity: 1, fill: alphaColor }}
                to={currentPolygon ? [
                    { opacity: 0.2, fill: hoverColor },
                    { opacity: 1, fill: hoverColor },
                    { opacity: 0.2, fill: hoverColor },
                    { opacity: 1, fill: hoverColor },
                ] : { opacity: 1, fill: alphaColor }}
                config={{
                    easing: easePoly.exponent(2),
                    duration: 300
                }}
                onRest={() => {
                    setPolygon(null)

                }}
                onStart={() => {
                    setFill(hexToRgba(polygon.fill, 0))
                }}
            >
                {springProps => (
                    // @ts-ignore
                    <animated.Line
                        points={data}
                        perfectDrawEnabled={false}
                        shadowForStrokeEnabled={false}
                        closed
                        {...springProps}
                    />
                )}
            </Spring>
            <Line
                stroke={stroke}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                fill={fill}
                points={data}
                closed
                onClick={handleClick}
                onTap={handleClick}
                perfectDrawEnabled={false}
                shadowForStrokeEnabled={false}
                id={"layer" + id}
            />
        </Group>
    )
}

export default Polygon
// Polygon.whyDidYouRender = true

// const AnimatedPolygon = ({ ...otherProps }) => {
//     const { polygon } = otherProps

//     return (
// <Spring
//                 from={{ opacity: 1, fill: alphaColor }}
//                 to={currentPolygon ? [
//                     { opacity: 0.2, fill: hoverColor },
//                     { opacity: 1, fill: hoverColor },
//                     { opacity: 0.2, fill: hoverColor },
//                     { opacity: 1, fill: hoverColor },
//                 ] : { opacity: 1, fill: alphaColor }}
//                 config={{
//                     easing: easePoly.exponent(2),
//                     duration: 300
//                 }}
//             >
//                 {springProps => (
//                     // @ts-ignore
//                     <animated.Line
//                         points={data}
//                         perfectDrawEnabled={false}
//                         shadowForStrokeEnabled={false}
//                         closed
//                         {...springProps}
//                     />
//                 )}
//             </Spring>
//     )
// }