import useWindowSize from '@/hooks/useWindowSize'
import { useMapStore } from '@/stores/mapStore'
import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import { Stage } from 'react-konva'
import Konva from 'konva'
import { Vector2d } from 'konva/lib/types'
import { KonvaEventObject } from 'konva/lib/Node'
import { useGlobalStore } from '@/stores/globalStore'

interface MapProps {
    // image: string
    // polygons: PolygonType[]
    // points: PointType[]
    // pointOptions: PointOptionsType
}

const scaleBy = 1.25

const isTouchEnabled =
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0;

// Do this to properly handle drag on touch devices.
Konva.hitOnDragEnabled = isTouchEnabled
const ZeroVector: Vector2d = { x: 0, y: 0 }

const Map: React.FC<MapProps> = ({ children }) => {
    const stageRef = useRef<any>(null)
    const lastCenter = React.useRef<Vector2d | null>(null)
    const lastDist = React.useRef<number>(0)

    const { width, height } = useWindowSize()

    // data selectors
    const zoomSeat = useMapStore(state => state.zoomSeat)
    const setZoomSeat = useMapStore(state => state.setZoomSeat)
    const activeLayer = useGlobalStore(state => state.activeLayer)
    const setStage = useMapStore(state => state.setStage)
    const size = useMapStore(state => state.size)
    const initialScale = useMapStore(state => state.initialScale)
    const setInitialScale = useMapStore(state => state.setInitialScale)
    
    const scaleStage = React.useCallback(
        (
            stage: Konva.Stage,
            center: Vector2d,
            stageScale: number,
            centerDelta: Vector2d = ZeroVector
        ) => {
            const currentScale = stage.scaleX()

            // local coordinates of center point
            const localCenter = {
                x: (center.x - stage.x()) / currentScale,
                y: (center.y - stage.y()) / currentScale,
            }

            const newScale = bound(
                stageScale,
                initialScale / 5,
                initialScale * 15
            )

            const newPos = {
                x: center.x - localCenter.x * newScale + centerDelta.x,
                y: center.y - localCenter.y * newScale + centerDelta.y,
            }

            stage.scale({ x: newScale, y: newScale })
            stage.position(newPos)
            stage.batchDraw()
        },
        [initialScale]
    )

    const handleScroll = React.useCallback(
        (event: KonvaEventObject<WheelEvent>) => {
            if (event.evt.defaultPrevented) {
                return
            }

            event.evt.preventDefault()
            const { currentTarget: stage } = event
            if (!(stage instanceof Konva.Stage)) {
                return
            }
            const scale = stage.scaleX()
            const newScale = event.evt.deltaY < 0 ? scale * scaleBy : scale / scaleBy

            scaleStage(
                stage,
                stage.getPointerPosition() ?? ZeroVector,
                newScale
            )
        },
        [scaleStage]
    )

    const resize = React.useCallback(() => {
        if (!size[0] || !size[1]) return

        const scaleX = width / size[0]
        const scaleY = (height - 60) / size[1]
        const newScale = Math.min(scaleX, scaleY)
        const stage = stageRef.current

        const newPos = {
            x: -(size[0] * newScale - width) / 2,
            y: -(size[1] * newScale - height + 60) / 2
        }

        setInitialScale(newScale)

        stage.scale({ x: newScale, y: newScale })
        stage.position(newPos)
        stage.batchDraw()

        if (zoomSeat) {
            moveTo(Number(zoomSeat))
        }

    }, [stageRef, size, width, height, zoomSeat])

    const onMouseDownHandler = React.useCallback((e) => {
        const container = e.target.getStage()?.container()

        if (container) {
            container.style.cursor = "grabbing"
        }
    }, [])

    const onMouseUpHandler = React.useCallback((e) => {
        const container = e.target.getStage()?.container()

        if (container) {
            container.style.cursor = "grab"
        }
    }, [])

    const handleTouchMove = React.useCallback(
        (event: KonvaEventObject<TouchEvent>) => {
          if (event.evt.defaultPrevented) {
            return
          }
    
          event.evt.preventDefault();
          const { currentTarget: stage } = event;
    
          if (!(stage instanceof Konva.Stage)) {
            return
          }
    
          if (event.evt.touches.length !== 2) {
            return
          }
    
          if (stage.isDragging()) {
            stage.stopDrag()
          }
    
          const [touch1, touch2]: any = event.evt.touches
          const p1 = { x: touch1.clientX, y: touch1.clientY }
          const p2 = { x: touch2.clientX, y: touch2.clientY }
          const newCenter = getCenter(p1, p2)
          const dist = getDistance(p1, p2)
    
          if (!lastCenter.current) {
            lastCenter.current = newCenter
          }
          if (!lastDist.current) {
            lastDist.current = dist
          }
    
          const centerDelta = {
            x: newCenter.x - lastCenter.current.x,
            y: newCenter.y - lastCenter.current.y,
          }

          const stageScale = (stage.scaleX() * (dist / lastDist.current))

          scaleStage(stage, lastCenter.current, stageScale, centerDelta)
    
          lastDist.current = dist
          lastCenter.current = newCenter
        },
        [scaleStage]
      )

    const multiTouchEnd = React.useCallback(
        (event: KonvaEventObject<TouchEvent>) => {
            lastCenter.current = null
            lastDist.current = 0
        },
        []
    )

    const multiTouchStart = React.useCallback(
        (event: KonvaEventObject<TouchEvent>) => {
            event.evt.preventDefault()

            const { currentTarget: stage } = event;

            if (event.evt.touches.length !== 2) {
                return;
            }

            stage.stopDrag()
        },
        []
    )

    
    const dragBoundFunc = React.useCallback(
        (pos) => boundFunc(pos, stageRef.current, width, height, size[0], size[1]),
        [size, stageRef, width, height]
    )
    // const moveTo = (point) => {
    //     if (!size || !point) return
        
    //     const [width, height] = size
    //     const stage = stageRef.current
    //     const el = stage.findOne('#point' + point)

    //     if (!el) return

    //     const currentScale = stage.scaleX()
    //     const pos = el.getAbsolutePosition(stage)
    //     const pointSize = size[0] * 0.1
    //     const maxCount = size[0] / pointSize
    //     const stageScale = stage.width() / (pointSize * (maxCount / 2))

    //     const finalScale = Math.max(currentScale, stageScale)

    //     const posPoint =  {
    //         x: -pos.x * finalScale + width / 2,
    //         y: -pos.y * finalScale + height / 2,
    //     }

    //     stage.scale({ x: finalScale, y: finalScale })
    //     stage.position(posPoint)
    //     stage.batchDraw()

    //     setZoomSeat(null)

    // }

    const moveTo = (point) => {
        if (!size || !point) return
        
        const [width, height] = size
        const stage = stageRef.current
        const el = stage.findOne('#point' + point)

        if (!el) return

        const currentScale = stage.scaleX()
        const pos = el.getAbsolutePosition(stage)
        const pointSize = size[0] * 0.1
        const maxCount = size[0] / pointSize
        const stageScale = width / (pointSize * (maxCount / 2)) 

        const finalScale = Math.max(currentScale, stageScale)

        const posPoint =  {
            x: -pos.x * stageScale + stage.width() / 2,
            y: -pos.y * stageScale + stage.height() / 2,
        }

        stage.scale({ x: finalScale, y: finalScale })
        stage.position(posPoint)
        stage.batchDraw()

        setZoomSeat(null)
    }



    useEffect(() => {
        resize()
    }, [size, activeLayer])

    useEffect(() => {
        if (zoomSeat) {
            moveTo(Number(zoomSeat))
        }
    }, [zoomSeat])

    useLayoutEffect(() => {
        if (stageRef.current) {
            setStage(stageRef.current)
        }
    }, [stageRef])

    return (
        <Stage
            ref={stageRef}
            width={width}
            height={height - 60}
            draggable
            onWheel={handleScroll}
            onMouseDown={onMouseDownHandler}
            onMouseUp={onMouseUpHandler}
            onTouchMove={handleTouchMove}
            onTouchEnd={multiTouchEnd}
            onTouchStart={multiTouchStart}
            dragBoundFunc={dragBoundFunc}
            style={{ cursor: 'grab' }}
        >
            {children}
        </Stage>

    )
}

export default Map

Map.whyDidYouRender = true

const bound = (value: number, min: number, max: number): number => Math.min(max, Math.max(min, value))
const getDistance = (p1: Vector2d, p2: Vector2d): number => Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
const getCenter = (p1: Vector2d, p2: Vector2d): Vector2d => ({
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
})

export const boundFunc = (pos, stage, width, height, stageWidth, stageHeight) => {
    // console.log('POS ', pos);
    
    const scale = stage.scaleX()
    const offset = Math.max(width, height) * 0.1
    
    const minX = -(stageWidth * scale) + offset
    const maxX = width - offset
    const minY = -(stageHeight * scale) + offset
    const maxY = height - offset

    let x = pos.x
    let y = pos.y

    if (x > maxX) {
        x = maxX
    }

    if (x < minX) {
        x = minX
    }

    if (y > maxY) {
        y = maxY
    }

    if (y < minY) {
        y = minY
    }

     return { x, y }
}