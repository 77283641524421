import React from 'react'
import Sidebar from '../Sidebar';
import TreeView from './TreeView';
import { useTree } from '@/api/hooks/useTree';
import { translate } from '@/i18n';

const Layers = () => {
    // fetch data hooks
    const { layers } = useTree()

    console.log(layers?.nodes)

    return (
        <Sidebar>
            <Sidebar.Header title="levels" />

            {layers?.tree ? (
                <TreeView data={layers.tree} />
            ) : (
                <div style={{ padding: '1rem 0' }}>{translate('loading')}...</div>
            )}
        </Sidebar>
    )
}

export default Layers