import { useState } from 'react'

const useSorting = (defaultSort: string = '', defaultDirection: 0 | 1 = 0) => {
    const [sort, setSort] = useState<string>(defaultSort)
    const [direction, setDirection] = useState<0 | 1>(defaultDirection)

    const handleSort = (newSort) => {
        if (newSort === sort) {
            setDirection(direction ? 0 : 1)
        } else {
            setDirection(0)
            setSort(newSort)
        }
    }

    return {
        sort, direction, handleSort
    }
}

export default useSorting