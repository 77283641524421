import { LOCALES } from "./../locales";

export default {
    [LOCALES.RUSSIAN]: {
        "log-in": "Войти",
        "log-out": "Выйти",

        "search": "Поиск",
        "loading": "Загрузка",
        "no-results": "Ничего не найдено",
        "select-period": "Выбрать период",
        "profile": "Профиль",

        "start-time": "Время начала",
        "end-time": "Время окончания",
        "start-date": "Дата начала",
        "end-date": "Дата окончания",
        "brand-name": "Карты Офиса",
        "bookings": "Бронирование",
        "employees": "Сотрудники",
        "objects": "Объекты",
        "reports": "Отчеты",
        "levels": "Уровни",
        "info": "Информация",
        "close": "Закрыть",
        "delete": "Удалить",
        "cancel": "Отмена",
        "confirm": "Подтвердить",

        "place": "Место",
        "booking": "Бронирование",
        "type": "Тип",
        "position": "Расположение",
        "description": "Описание",
        "all-bookings": "Все брони",
        "past-bookings": "Прошедшие",
        "current-bookings": "Текущие",
        "future-bookings": "Будущие",
        "constant-bookings": "Постоянные",
        "weekly-bookings": "Еженедельные",
        "full-name": "ФИО",
        "location": "Место",
        "date": "Дата",
        "common": "Обычная",
        "recurrent": "Еженедельная",
        "constant": "Постоянная",
        "success": "Успешно",
        "error": "Ошибка",
        "booking-deleted": "Бронирование удалено!",
        "failed-to-delete-booking": "Не удалось удалить бронирование",
        "delete-booking": "Удалить бронирование?",
        "delete-booking-confirm": "Вы уверены, что хотите удалить бронирование?",



        "auth_auth-text": "Авторизация",
        "auth_login": "Логин",
        "auth_password": "Пароль",
        "appbar_datepicker-title": "Показать брони на карте",
        "search-bookings-by-user-or-object": "Поиск по сотруднику или мест",
        "object-type": "Тип места",
        "date-display-error": "Ошибка отображения даты",
        "search-by-name": "Поиск по названию",
        "all-levels": "Все уровни",
        "all": "Все",
        "service": "Обслуживание",
        "about-object": "Об объекте",
        "object-types": "Тип",
        "object-name": "Наименование",
        "show-on-map": "Показать на карте",
        "more": "Подробнее",
        "search-by-userdata": "Поиск по данным сотрудника",
        "booking_category": "Категории бронирования",
        "about-employee": "О сотруднике",
        "not-authorized": "Не авторизован",
        "booking-added": "Бронирование добавлено",
        "booking-added-confirm": "Ваше бронирование было успешно добавлено",
        "allowed-for-authorized": "Доступно только авторизованным пользователям",
        "booking-type": "Тип бронирования",
        "select-location": "Выберите расположение",
        "choose-location": "Выберите расположение",
        "select-object": "Выбор место",
        "choose-employee": "Выберите сотрудника",
        "select-employee": "Выбор  сотрудника",
        "start-datetime": "Дата и время начала",
        "end-datetime": "Дата и время окончания",
        "already-booked": "Занято другим сотрудником",
        "booked-by-me": "Другая моя бронь",
        "my-parallel-bookings": "Мои параллельные брони",
        "current-booking": "Текущая бронь",
        "free": "Свободные",
        "show": "Показать",
        "hide": "Скрыть",
        "no-parallel-bookings": "Параллельные брони отсутствуют",
        "bookings-for-period": "Брони на период:",
        "show-bookings-between": "Показано с {start} по {end}",
        "calendar-initialize-error": "Не удалось инициализировать календарь",
        "select": "Выбрать",
        "add-interval": "Добавить интервал",
        "week-days": "День недели",
        "monday": "пн",
        "tuesday": "вт",
        "wensday": "ср",
        "thursday": "чт",
        "friday": "пт",
        "saturday": "сб",
        "sunday": "вс",
        "start-working": "Начало работы",
        "end-working": "Окончание работы",
        "save": "Сохранить",
        "submit": "Добавить",
        "resolver_state_1": "Текущая бронь пересекается с уже существующей бронью",
        "resolver_state_2": "Текущая бронь пересекается с чужой бронью",
        "resolver_state_4": "Невозможно создать бронь для данного объекта",
        "resolver_state_8": "Вы не можете создавать новые брони для данного типа объектов",
        "resolver_state_10": "Объект для бронирования не найден",
        "bookings-report": "Список броней на выбранный день",
        "place-report": "Нагрузка на рабочее место",
        "report-template": 'Отчет: "{name}"',
        "free-places-v1": "Свободные места за период",
        "non-free-places-v1": "Занятые места за период",

        "report-full-name": "ФИО",
        "report-object": "Место",
        "report-booking-type": "Тип",
        "report-date": "Дата",
        "report-place-name": "Название места",
        "report-laod": "занятости",

        "report-type_name": "Тип",
        "report-name": "Название",
        "report-parent": "Уровень",

        "not-specified": "Не указано",

        "add-report": "Создание отчета",
        "create-report": "Создать отчет",
        "select-report": "Выберите тип отчета",
        "select-colummns": "Выберите данные для вывода",
        "user": "Пользователь",
        "save-pdf": "Сохранить как PDF",
        "save-csv": "Сохранить как CSV",
        "select-data-to-display": "Выберите данные для вывода",
        "place-name": "Наименование места",
        "name": "Наименование",

        "create-report-bookings": "Отчет по бронированию",
        "create-report-employees": "Отчет по сотрудникам",
        "create-report-objects": "Отчет по объектам",
        "object": "Объект",
        "no-place-users": "Сотрудники без мест",

        "book": "Забронировать",
        "project-not-found": "Проект не найден",
        "go-to-test-project": "Перейти к тест проекту",
        "employee": "Сотрудник",
        "object-service": "Обслуживание объекта",
        "service-type": "Тип обслуживания",
        "cleaning": "Уборка",
        "maintenance": "Техническое обслуживание",
        "more-info": "Подробная информация",
        "describe": "Опишите подробнее",
        "send": "Выбрать",
        "service-submitted": "Заявка принята в обработку",

        "error-load-metadata": "Не удалось загрузить метаданные проекта",
        "edit": "Редактировать",
        "map-loading": "Загрузка карты",
        "data-loading": "Загрузка данных",
        "my-bookings": "Мои брони",
        "search-bookings-by-user": "Поиск по сотруднику",
        "search-bookings-by-object": "Поиск по месту",
    }
}